.wellTableContainer {
  padding: 16px;
  border-radius: 16px;
  min-width: min-content;
  background-color: #2D2E44;
}

.wellTable {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: white;
  border-collapse: separate;
  line-height: 18px;
  border-spacing: 0;
}

.wellTable thead tr th {
  color: #737496;
  font-weight: 700;
  padding: 16px 12px;
}

.wellTable tbody tr td {
  padding: 16px 12px;
  border-top: 1px solid #353750;
}

.wellTable thead tr th:first-of-type {
  border-top-left-radius: 8px;
}

.wellTable thead tr th:last-of-type {
  border-top-right-radius: 8px;
}

.wellTable tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 8px;
}

.wellTable tbody tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 8px;
}

.wellTable tbody tr {
  cursor: pointer;
  transition: background-color ease-out 0.1s;
}

.wellTable tbody tr:hover td {
  background-color: rgba(65, 67, 97, 1);
}

.wellTable.wellSectionsTable thead tr th:first-of-type {
  width: 50%;
}

.wellTable.wellSectionsTable thead tr th:nth-of-type(2),
.wellTable.wellSectionsTable thead tr th:nth-of-type(3) {
  width: 25%;
}

.wellTable.allWellsTable thead tr th:first-of-type,
.wellTable.allWellsTable thead tr th:nth-of-type(2),
.wellTable.allWellsTable thead tr th:nth-of-type(3) {
  width: 15%;
}

.wellTable.allWellsTable thead tr th:nth-of-type(4) {
  width: 23%;
}

.wellTable.allWellsTable thead tr th:nth-of-type(5),
.wellTable.allWellsTable thead tr th:nth-of-type(6) {
  width: 12%;
}

/*
  global styles
  TODO: move to /kit/styles
*/  

.formFieldRow {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
  margin-bottom: 10px;
  background-color: #4260B2;
}

.formFieldRowDisplayOnly {
  border: 1px dashed silver;
  background-color: transparent;
}

.formFieldLabel {
  width: 125px;
  text-align: right;
  margin-right: 10px;
  background-color: transparent;
  color: #ffffff;
  margin-top: auto;
  margin-bottom: auto;
}

.formFieldInput {
  flex: 1;
  width: 150px;
  background-color: #ffffff;
  color: darkslategray;
  border: 1px dashed transparent;
  padding: 10px 15px 10px 5px;
  border-radius: 0px;
  font-weight: 700;
  text-align: right;
  height: 40px;
}

.formFieldInputViewOnly {
  background-color: #808080;
  color: black;
  border: none;
}

.formFieldInputClear {
  background-color: rgb(52, 53, 79);
  border-radius: 0px;
  border: none;
}

.formFieldSelect {
  flex: 1;
  background-color: transparent;
  color: darkslategray;
  font-weight: 700;
  border-radius: 6px;
  margin-left: 8px;
  border: 1px solid transparent;
}

.formFieldInputViewOnlyLabel {
  color: silver;
  background-color: transparent;
  text-align: right;
}

.formFieldInputViewOnlyText {
  background-color: transparent;
  color: #fff;
  text-align: left;
}

.wellStatusSelect:focus-visible {
 outline: none;
}

.sort-date-nav:hover {
  color: #ffffffa8;
  transition: color 0.65s ease-in-out;
}