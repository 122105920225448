/*
  tables
*/
.table {
  width: calc(100% - 16px);
  border: none;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border-collapse: collapse;
}

.table thead {
  display: block;
  width: 100%;
}

.table tbody {
  display: block;
  overflow-y: scroll;
}


.table thead tr {
  background-color: transparent;
}

.table thead th {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #737496;
  padding: 8px 12px;
  /* border-top: 1px solid #353750; */
  border-left: 1px solid #353750;
  border-right: 1px solid #353750;
}

.table tbody td {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #737496;
  padding: 8px 12px;
}

.table tbody td.hl {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #ffffff;
  padding-bottom: 4px;
  letter-spacing: 0.3px;
}

.table tbody tr {
  transition: background-color ease-out 0.1s;
}

.table tbody tr td {
  transition: background-color ease-out 0.1s;
  border: 1px solid #353750;
}

.table tbody tr:hover td {
  background-color: rgba(65, 67, 97, 1);
}

.table thead tr th {
  background: #2f3047;
}

.table thead tr th:first-child {
  border-left: none;
  border-radius: 8px 0px 0px 0px;
}

.table thead tr th:last-child {
  border-right: none;
  border-radius: 0px 8px 0px 0px;
}

.table tbody tr td:first-child {
  border-left: none;
  border-right: none;
}

.table tbody tr td:last-child {
  border-right: none;
}

.table tbody tr:last-child td {
  border-bottom: none;
}

.table.bha tbody tr:last-child td {
  border-bottom: 1px solid #353750;
}

.table tbody tr:last-child td:first-child {
  border-radius: 0px 0px 0px 8px;
}

.table tbody tr:last-child td:last-child {
  border-radius: 0px 0px 8px 0px;
}

.table tbody tr:last-child .ip_length {
  border-radius: 0px 0px 8px 0px;
}


/* new table styles */

.pipe-list-table thead {
  width: 100%;
}

.pipe-list-table tbody {
  overflow-y: scroll;
}

.pipe-list-table thead tr {
  background-color: transparent;
}

.pipe-list-table thead th {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #737496;
  padding: 8px 12px;
  /* border-top: 1px solid #353750; */
  border-left: 1px solid #353750;
  border-right: 1px solid #353750;
}

.pipe-list-table tbody td {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #737496;
  padding: 8px 12px;
}

.pipe-list-table tbody td.hl {
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #ffffff;
  padding-bottom: 4px;
  letter-spacing: 0.3px;
}

.pipe-list-table tbody tr {
  transition: background-color ease-out 0.1s;
}

.pipe-list-table tbody tr td {
  transition: background-color ease-out 0.1s;
  border: 1px solid #353750;
}

.pipe-list-table tbody tr:hover td {
  background-color: rgba(65, 67, 97, 1);
}

.pipe-list-table thead tr th {
  background: #2f3047;
}

.pipe-list-table thead tr th:first-child {
  border-left: none;
  border-radius: 8px 0px 0px 0px;
}

.pipe-list-table thead tr th:last-child {
  border-right: none;
  border-radius: 0px 8px 0px 0px;
}

.pipe-list-table tbody tr td:first-child {
  border-left: none;
  border-right: none;
}

.pipe-list-table tbody tr td:last-child {
  border-right: none;
}

.pipe-list-table tbody tr:last-child td {
  border-bottom: none;
}

/*
  dashboard activity table
*/
.dashboardRecentActivity {
  width: 100%;
  color: #929090;
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
  border-spacing: 0px;
  text-align: left;
  padding: 2px 8px;
}

.dashboardRecentActivity thead tr th {
  border-bottom: 1px solid #696969;
}

.dashboardRecentActivity thead tr th,
.dashboardRecentActivity tbody tr td {
  padding: 4px;
}

.dashboardRecentActivity tbody tr:hover td {
  background-color: rgba(65, 67, 97, 1);
  cursor: pointer;
}
