  /*
    boxes
  */
  .uiContainer {
    background-color: transparent;
    border: 1px solid #44455f;
    padding: 10px;
    border-radius: 8px;
    font-weight: 600;
    flex: auto;
    height: 250px;
    min-width: 280px;
    margin: 15px;
    flex: 1 1 220px;
  }

  .uiContainerShallow {
    height: 200px;
  }

  .uiContainerWide {
    height: 275px;
    min-width: 300px;
    flex: 1 1 900px;
  }

  .uiContainerShort {
    height: 180px;
    min-width: 300px;
    flex: 1 1 900px;
  }
  .uiContainerLimited {
    background-color: transparent;
    border: 1px solid #44455f;
    padding: 10px;
    border-radius: 8px;
    font-weight: 600;
    flex: auto;
    height: 250px;
    min-width: 280px;
    max-width: 382px;
    margin: 15px;
    flex: 1 1 220px;
  }

  /*
    title
  */
  .uiContainerTitleWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .uiContainerTitle {
    color: rgb(202, 197, 197);
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 2px 8px;
    margin-bottom: 4px;
  }

  /*
    text
  */
  .uiContainerText {
    color: white;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
  }

  /*
    link
  */
  .uiContainerLinkLabel {
    color: rgb(110, 108, 108);
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
    white-space: nowrap;
  }

  .uiContainerLinkText {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
  }

  .uiContainerQuickLinkWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    row-gap: 12px;
    flex-wrap: wrap;
    justify-self: center;
  }

  .uiContainerQuickLink {
    color: white;
    text-wrap: nowrap;
    font-size: 14px;
    text-decoration: none;
    transition: color ease-out 0.1s;
    border: 1px dotted dodgerblue;
    padding: 20px;
    border-radius: 8px;
    font-weight: bold;
    height: max-content;
  }

  .uiContainerQuickLink:hover {
    background-color: #5c85f5;
  }

  .uiContainerSecondaryLink {
    color: #6f6d6d;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 400;
    text-decoration: none;
    transition: color ease-out 0.1s;
    padding: 4px 8px;
  }

  .uiContainerSecondaryLink:hover {
    background-color: #5c85f5;
    color: #fff;
  }
