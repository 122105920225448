.broomstickTableSettings {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 100px;
  width: 100%;
  margin-bottom: 8px;
  border-top: 1px solid #3d3d3d;
  border-radius: 8px 8px 8px 8px;
  font-size: 14px;
  color: rgb(255, 255, 255);
  padding: 10px;
  background-color: rgb(58, 60, 86);
  padding-bottom: 2rem;
}

.broomstick-field {
  position: relative;
  display: flex;;
  font-size: 14px;
  margin-bottom: 5px;
}

.broomstick-field input {
  border: 1px dashed;
  border-color: #353750;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #2b2c41;
  padding: 8px 16px 5px 16px;
  border-radius: 8px;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  transition: border-color 0.2s ease-out;
  width: 160px;
}

.broomstick-field input {
  height: 40px;
}

.broomstick-field input:hover {
  border-color: rgba(92, 133, 245, 0.8);
}

.broomstick-field input:focus {
  border-color: #5c85f5;
  background-color: #fff;
  color: #000000;
}

.broomstick-field .placeholder {
  font-style: italic;
  position: absolute;
  left: 16px;
  width: calc(100% - 24px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 21px;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.4);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.broomstick-table-labels {
  padding: 8px;
  text-align: right;
  height: 20px;
}

.broomstickContextValues {
  padding: 8px;
  text-align: left;
  color: silver;
  height: 20px;
}

.broomstick-input {
  background-color: rgb(62, 64, 86);
  color: #fff;
  border-radius: 6px;
  padding: 4px;
  border: none;
}

.broomstick-input {
  background-color: rgb(62, 64, 86);
  font-weight: 700;
  min-height: 30px;
  max-height: 30px;
  text-align: right;
}

.broomstick-input:focus {
  background-color: silver;
  color: #000000;
  font-weight: 700;
  border: none !important;
  outline: none !important;
}
.broomstick-input:focus {
  border: none !important;
  outline: none !important;
}

.item-unit {
  text-align: left;
  font-size: 14pt;
  color: rgb(255, 255, 255);
  text-transform: none;
}
