
#survey .survey-row:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#survey .survey-row:first-child .survey-item:first-child {
  border-top-left-radius: 8px;
}

#survey .survey-row:first-child .survey-item:last-child {
  border-top-right-radius: 8px;
}

.survey-cell-input {
  font-size: 10px !important;
}


/* data */
.survey-cell-input {
  border: none;
  outline: none;
  border-radius: 0px;
  min-width: 100%;
  width: 100%;
  color: #fff;
  background-color: transparent;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 6px;
  text-align: center;
  height: 32px;
}
.survey-cell-input:focus,
.survey-cell-input:hover,
.survey-cell-input:active {
  background-color: #fff;
  color: #000000;
  border: none;
  border-radius: 0px;
  outline: none;
}
.survey-cell-input:hover {
  cursor: pointer;
}

/* grid */
#survey {
  margin-bottom: 16px;
  padding-bottom: 1rem;
  display: grid;
}

.survey-row-head {
  display: grid;
  grid-gap: 0px;
  grid-template-columns:
    10%
    30%
    30%
    30%;
  grid-auto-rows: minmax(min-content, max-content);
  max-width: 29rem;
  font-size: 12px;
  padding: 0px;
  color: #737496;
  height: 50px;
  background-color: #2f3047;
  position: sticky; 
  overflow: hidden; 
  top: 0px; 
  z-index: 1;
}

.survey-row-head .survey-head-item:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-left: 1px solid #484960;
  border-top-left-radius: 8px;
}

.survey-row-head .survey-head-item:last-child {
  border-top-right-radius: 8px;
  border-right: 1px solid #484960;
}

.survey-row-head .survey-head-item:first-child {
  border-right: 1px solid #484960;
}

.survey-row-head .survey-head-item:not(:first-child, :last-child ) {
  border-right: 1px solid #484960;
  max-width: 138.89px;
}

.survey-row-head .survey-head-item {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  text-align: center;
  border-bottom: 1px solid #484960;
  border-top: 1px solid #484960;
}

.survey-row {
  display: grid;
  grid-gap: 0px;
  grid-template-columns:
    10%
    30%
    30%
    30%;
  grid-auto-rows: minmax(min-content, max-content);
  max-width: 29rem;
  font-size: 12px;
  padding: 0px;
  color: #737496;
  text-align: center;
  height: 32px;
}

#survey .survey-row:first-child {
  background-color: #42435c;
  color: dodgerblue;
  height: 50px;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  top: 0;
  z-index: 2;
}
#survey .survey-row:first-child .survey-item:first-child {
  z-index: 3;
}
#survey .survey-row {
  color: white; 
}

.survey-row .survey-item {
  border-bottom: 1px solid #484960;
}

.survey-row .survey-item:not(:last-child) {
  border-right: 1px solid #484960;
  border-bottom: 1px solid #484960;
}

#survey .survey-row:not(:first-child, :last-child) {
  border-right: 1px solid #484960;
}

.survey-row:last-child .survey-item:first-child {
  border-bottom-left-radius: 8px;
  border-bottom: 1px solid #484960;
}

.survey-row:last-child .survey-item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom: 1px solid #484960;
  border-right: 1px solid #484960;
}

.survey-row .survey-item:first-child {
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #484960;
}
